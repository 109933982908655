.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.grayText {
  color: #777777;
  font-size: 15px;
  /* font-stretch: extra-condensed; */
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 5px
}

.descriptionText {
  color: #525252;
  font-size: 17px;
}

.checked{
  color: #FADB60;
  padding:2px;
  padding-top:15px;
  padding-bottom:20px;
}

.unchecked{
  color: #EDEDED;
  padding:2px;
  padding-top:15px;
  padding-bottom:20px;
}

.mainText {
  font-size: 20px;
  margin-top:5px;
  margin-bottom:7px;
  text-transform: lowercase;
  font-weight: 600;
}

.stock {
  color:darkcyan;
  font-size: 20px;
  margin-top:5px;
  margin-bottom:7px;
  text-transform: uppercase;
  font-weight: 600;
}

.marketText {
  font-size: 20px;
  padding-top:2px;
  padding-bottom:2px;
  padding-left:10px;
  padding-right:10px;
  margin-top:10px;
  margin-bottom:10px;
  text-transform: lowercase;
  font-weight: 600;
  border-radius: 5px;
  display:inline-block;
}

.borderedArticle{
  height: 100%;
  display:inline-block;
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical; */ 
}

.source {
  size:30px;
}

.basicLogo{
  width: 100px;
  float:right;
  padding-top:40px;
  padding-bottom:15px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}


.borderedNobias{
  border-radius:20px;
  height: 100%;
  border:1px solid #d9d9d9;
  display:inline-block;
  /* overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical; */ 
}

.widgets {
  transition: all 5s;
  color: green;
  opacity: 1;
}

.widgets .hide{
  color: black;
  pointer-events:none;
}

.myrow {
  display: flex;
  flex-direction: row;
}

.mycol1{
  flex-grow: 2;
}

.mycol2{
  flex-grow: 1;
}

@media only screen and (max-width: 992px) {
  .myrow{
    display: block;
  }
  .mycol1{
    display: block;
  }

  .mycol2{
    display: block;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
